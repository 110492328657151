<script setup>
import { useInstanceStore } from '@/store/backendInstances';
import { computed, onMounted, ref } from 'vue';
import { inputRequiredRule } from '@/composables/inputRules';

const instanceStore = useInstanceStore()

const props = defineProps({
  globalUniqueIdentifier: { type: String, required: true },
})
const hasAccepted = defineModel('accepted', { type: Boolean, default: false })
const acceptedVersion = defineModel('version', { type: String })
const rules = [inputRequiredRule]

const checkbox = ref(null)

const currentEULA = computed(() => instanceStore.eulas[props.globalUniqueIdentifier]?.['current'])


// eslint-disable-next-line no-unused-vars
const isValid = computed(() => checkbox.value?.isValid)

// eslint-disable-next-line no-unused-vars
const isValidating = computed(() => checkbox.value?.isValidating)

// eslint-disable-next-line no-unused-vars
const validate = () => checkbox.value?.validate()
onMounted(async () => {
  hasAccepted.value = false
  if (!currentEULA.value) await instanceStore.getCurrentEula(props.globalUniqueIdentifier)
  acceptedVersion.value = currentEULA.value.version
})
</script>

<template>
  <v-checkbox
    v-if="currentEULA"
    ref="checkbox"
    v-model="hasAccepted"
    :rules="rules"
  >
    <template #label>
      {{ $t("eula.accept_text") }}&nbsp; <a
        :href="currentEULA.url"
        target="_blank"
        @click.stop
      > {{ $t("eula.eula") }} </a>
    </template>
  </v-checkbox>
  <v-skeleton-loader
    v-else
    type="subtitle"
  />
</template>
<style scoped>

</style>
