<script setup>
import { useSettingsStore } from '@/store/settings';
import { useI18n } from 'vue-i18n';
import { useBranchString } from '@/composables/version.js';
const settingsStore = useSettingsStore()
const { locale } = useI18n({ useScope: 'global' })

const flags = {
  en: 'https://flagcdn.com/48x36/us.png',
  de: 'https://flagcdn.com/48x36/de.png',
  debug: 'https://flagcdn.com/48x36/aq.png',
}

const availableLanguages = [
  { id: 'de', title: 'Deutsch', avatar: flags['de'] },
  { id: 'en', title: 'English', avatar: flags['en'] },
]
if(['dev', 'staging', 'preview', 'local'].includes(useBranchString()))
  availableLanguages.push({ id: 'debug', title: 'DEBUG', avatar: flags['debug'] })

function updateLanguage(language) {
  settingsStore.setLanguage(language)
  locale.value = language
}
</script>

<template>
  <v-autocomplete
    variant="outlined"
    :items="availableLanguages"
    :model-value="settingsStore.language"
    item-text="title"
    item-value="id"
    :item-props="true"
    @update:model-value="updateLanguage"
  >
    <template #item="{ item, props }">
      <v-list-item
        v-bind="props"
        :title="item?.raw?.title"
        :prepend-avatar="item?.raw?.avatar"
      />
    </template>
    <template #selection="{ item, props }">
      <v-list-item
        v-bind="props"
        :title="item?.raw?.title"
        :prepend-avatar="item?.raw?.avatar"
      />
    </template>
  </v-autocomplete>
</template>

<style scoped>

</style>
