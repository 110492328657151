/**
 * main.js
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */

// Components
import App from './App.vue'
import EmojiPicker from 'vue3-emoji-picker'
import { registerSW } from 'virtual:pwa-register'

// import css
import 'vue3-emoji-picker/css'

import './default-style.css'

// Composables
import { createApp } from 'vue'

// Plugins
import { registerPlugins } from '@/plugins'
import { useMiscellaneousStore } from '@/store/miscellaneous';
import { useLogsStore } from '@/store/logs.js';
import Highcharts from 'highcharts';
import 'shepherd.js/dist/css/shepherd.css'



const app = createApp(App)

registerPlugins(app)

import i18n from './plugins/i18n'

const { t } = i18n.global


Highcharts.setOptions({
  lang: {
    contextButtonTitle: t('charts.chart_internationalization.contextButtonTitle'),
    decimalPoint: t('charts.chart_internationalization.decimalPoint'),
    downloadCSV: t('charts.chart_internationalization.downloadCSV'),
    downloadJPEG: t('charts.chart_internationalization.downloadJPEG'),
    downloadPDF: t('charts.chart_internationalization.downloadPDF'),
    downloadPNG: t('charts.chart_internationalization.downloadPNG'),
    downloadSVG: t('charts.chart_internationalization.downloadSVG'),
    downloadXLS: t('charts.chart_internationalization.downloadXLS'),
    exitFullscreen: t('charts.chart_internationalization.exitFullscreen'),
    hideData: t('charts.chart_internationalization.hideData'),
    months: [
      t('charts.chart_internationalization.months[0]'),
      t('charts.chart_internationalization.months[1]'),
      t('charts.chart_internationalization.months[2]'),
      t('charts.chart_internationalization.months[3]'),
      t('charts.chart_internationalization.months[4]'),
      t('charts.chart_internationalization.months[5]'),
      t('charts.chart_internationalization.months[6]'),
      t('charts.chart_internationalization.months[7]'),
      t('charts.chart_internationalization.months[8]'),
      t('charts.chart_internationalization.months[9]'),
      t('charts.chart_internationalization.months[10]'),
      t('charts.chart_internationalization.months[11]'),
    ],
    numericSymbols: [
      t('charts.chart_internationalization.numericSymbols[0]'),
      t('charts.chart_internationalization.numericSymbols[1]'),
      t('charts.chart_internationalization.numericSymbols[2]'),
      t('charts.chart_internationalization.numericSymbols[3]'),
      t('charts.chart_internationalization.numericSymbols[4]'),
      t('charts.chart_internationalization.numericSymbols[5]'),
    ],
    printChart: t('charts.chart_internationalization.printChart'),
    resetZoom: t('charts.chart_internationalization.resetZoom'),
    resetZoomTitle: t('charts.chart_internationalization.resetZoomTitle'),
    shortMonths: [
      t('charts.chart_internationalization.shortMonths[0]'),
      t('charts.chart_internationalization.shortMonths[1]'),
      t('charts.chart_internationalization.shortMonths[2]'),
      t('charts.chart_internationalization.shortMonths[3]'),
      t('charts.chart_internationalization.shortMonths[4]'),
      t('charts.chart_internationalization.shortMonths[5]'),
      t('charts.chart_internationalization.shortMonths[6]'),
      t('charts.chart_internationalization.shortMonths[7]'),
      t('charts.chart_internationalization.shortMonths[8]'),
      t('charts.chart_internationalization.shortMonths[9]'),
      t('charts.chart_internationalization.shortMonths[10]'),
      t('charts.chart_internationalization.shortMonths[11]'),
    ],
    shortWeekdays: [
      t('charts.chart_internationalization.shortWeekdays[0]'),
      t('charts.chart_internationalization.shortWeekdays[1]'),
      t('charts.chart_internationalization.shortWeekdays[2]'),
      t('charts.chart_internationalization.shortWeekdays[3]'),
      t('charts.chart_internationalization.shortWeekdays[4]'),
      t('charts.chart_internationalization.shortWeekdays[5]'),
      t('charts.chart_internationalization.shortWeekdays[6]'),
    ],
    thousandsSep: t('charts.chart_internationalization.thousandsSep'),
    viewData: t('charts.chart_internationalization.viewData'),
    viewFullscreen: t('charts.chart_internationalization.viewFullscreen'),
    weekdays: [
      t('charts.chart_internationalization.weekdays[0]'),
      t('charts.chart_internationalization.weekdays[1]'),
      t('charts.chart_internationalization.weekdays[2]'),
      t('charts.chart_internationalization.weekdays[3]'),
      t('charts.chart_internationalization.weekdays[4]'),
      t('charts.chart_internationalization.weekdays[5]'),
      t('charts.chart_internationalization.weekdays[6]'),
    ],
  },
})



app.component('EmojiPicker', EmojiPicker)

const backgroundServiceWorkerUpdateInterval = 1000 * 60 * 60
const updateSW = registerSW({
  onNeedRefresh() {
    const miscellaneousStore = useMiscellaneousStore()
    miscellaneousStore.setUpdateAvailable(updateSW)
  }, onRegisteredSW(_, swRegistration) {
    swRegistration && setInterval(() => {
      swRegistration.update().then().catch(e =>
        useLogsStore().addLogEntry({
          message: 'Failed to update service worker',
          error: e,
          tag: 'main',
          globalUniqueIdentifier: null,
          level: 'ERROR',
        }))
    }, backgroundServiceWorkerUpdateInterval)
  },
  onOfflineReady() {
  },
})
const channel = new BroadcastChannel('force-refresh')
channel.onmessage = () => {
  window.location.href = window.location.origin
}
useLogsStore().addLogEntry({ message: 'New Session from ' + window.location.origin + ' Started!', tag: 'main', globalUniqueIdentifier: null, level: 'INFO' })
app.config.errorHandler = (err, vm, info) => {
  useLogsStore().addLogEntry({ message: 'Render-Error ' + info, tag: 'RENDER', globalUniqueIdentifier: null, level: 'ERROR', error: err })
}

app.mount('#app')
