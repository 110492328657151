import { createRouter, createWebHistory } from 'vue-router'
import { useInstanceStore } from '@/store/backendInstances'
import { useLogsStore } from '@/store/logs.js'
import BackendAPI from '@/BackendAPI.js'

const routes = [
  {
    path: '/',
    children: [
      {
        path: '/logs',
        name: 'Logs',
        component: () => import('@/views/Logs.vue'),
      },
      {
        path: '',
        name: 'Home',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "home" */ '@/components/InstanceList.vue'),
      },
      {
        path: '/overview',
        name: 'Overview',
        component: () => import('@/views/QuickInstanceOverview.vue'),
      },
      {
        path: '/settings',
        name: 'Settings',
        component: () => import('@/views/LocalSettings.vue'),
        meta: {
          title: 'router_elements.settings',
          backButton: true,
        },
      },
      {
        path: '/ssoAuthentication',
        name: 'Senator Authentication',
        component: () => import('@/views/SenatorAuthentication.vue'),
      },
      {
        path: '/addInstance',
        children: [
          {
            path: '',
            name: 'Add Instance',
            component: () => import('@/views/AddInstance.vue'),
          },
          {
            path: 'new',
            name: 'Create Instance',
            component: () => import('@/views/CreateInstance.vue'),
          },
          {
            path: 'sso',
            name: 'Single Sign On',
            children: [
              {
                path: 'serviceProviders',
                name: 'Select ServiceProvider',
                component: () => import('@/views/SelectServiceProviders.vue'),
              },
            ],
          },
        ],
      },
      {
        path: '/ql',
        children: [
          {
            path: 'email-verification',
            component: () => import('@/views/quickLinks/EmailVerification.vue'),
            name: 'Email Verification',
          },
          {
            path: 'account-provisioning',
            component: () => import('@/views/quickLinks/SetPassword.vue'),
            name: 'Account Provisioning',
          },
        ],
      },
      {
        path: '/admiral/:globalUniqueIdentifier/',
        component: () => import('@/views/AdmiralDashboard.vue'),
        name: 'AdmiralDashboard',
      },
      {
        path: '/instance/:globalUniqueIdentifier',
        alias: ['/data-collector/:globalUniqueIdentifier'],
        component: () => import('@/views/InstanceDashboard.vue'),
        name: 'InstanceDashboard',
        redirect: to => {
          const instanceStore = useInstanceStore()
          const instance = instanceStore.instances[to.params.globalUniqueIdentifier]
          if (instance.instanceType === 'barn')
            return { name: 'CameraList' }
          if (instance.instanceType === 'senator')
            return { name: 'Senator Overview' }
          if (instance.instanceType === 'admiral')
            return { name: 'VMSInstanceList' }
          if (instance.instanceType === 'data_collector')
            return { name: 'DataCollectorOverview' }
          return { name: 'Config' }
        },
        children: [
          {
            path: 'data-collector',
            name: 'DataCollectorOverview',
            component: () => import('@/views/data-collector/DataCollectorDashboard.vue'),
            meta: {
              title: 'router_elements.data_collector.overview',
              backButton: false,
            },
          },
          {
            name: 'DataCollectorFileUpload',
            path: 'data-collector/file-upload',
            component: () => import('@/views/data-collector/DataCollectorFileUpload.vue'),
            meta: {
              title: 'router_elements.data_collector.file_upload',
              backButton: true,
            },
          },
          {
            name: 'Data Collector Annotation',
            path: 'data-collector/:recordId/annotate/:annotationId',
            props: true,
            component: () => import('@/views/data-collector/DataCollectorAnnotate.vue'),
            meta: {
              title: 'router_elements.data_collector.annotate',
              temporaryNavigationDrawer: true,
              backButton: true,
            },
          },
          {
            name: 'Data Collector Annotate',
            path: 'data-collector/:recordId/annotate',
            props: true,
            component: () => import('@/views/data-collector/DataCollectorAnnotate.vue'),
            meta: {
              title: 'router_elements.data_collector.annotate',
              temporaryNavigationDrawer: true,
              backButton: true,
            },
          },
          {
            name: 'Data Collector Annotate Next',
            path: 'data-collector/next',
            component: () => import('@/views/data-collector/DataCollectorAnnotateNext.vue'),
            meta: {
              title: 'router_elements.data_collector.annotate_next',
              backButton: true,
              temporaryNavigationDrawer: true,
            },
          },
          {
            name: 'Data Collector Annotations',
            path: 'data-collector/annotations',
            component: () => import('@/views/data-collector/Annotations.vue'),
            meta: {
              title: 'router_elements.data_collector.annotations',
              backButton: true,
            },
          },
          {
            name: 'DataCollector Records',
            path: 'data-collector/records',
            component: () => import('@/views/data-collector/DataCollectorRecords.vue'),
            meta: {
              title: 'router_elements.data_collector.records',
              backButton: true,
            },
          },
          {
            name: 'Data Collector Record',
            path: 'data-collector/records/:recordId',
            props: true,
            component: () => import('@/views/data-collector/DataCollectorRecordView.vue'),
            meta: {
              title: 'router_elements.data_collector.record',
              backButton: true,
            },
          },
          {
            path: 'server-management/:guid',
            name: 'Server Management',
            component: () => import('@/views/ServerManagement.vue'),
            meta: {
              title: 'router_elements.server_management',
              backButton: true,
            },
            props: true,
          },
          {
            path: 'authorization-management',
            name: 'Authorization Management',
            component: () => import('@/views/AuthorizationManagement.vue'),
            meta: {
              title: 'router_elements.authorization_management',
              backButton: true,
            },
          },
          {
            path: 'vms-instances',
            name: 'VMSInstanceList',
            component: () => import('@/views/VMSInstanceList.vue'),
            meta: {
              title: 'router_elements.vms_instances',
              backButton: false,
            },
          },
          {
            path: 'senator',
            name: 'Senator Overview',
            component: () => import('@/views/SenatorOverview.vue'),
            meta: {
              title: 'router_elements.senator_overview',
              backButton: false,
            },
          },
          {
            path: 'config',
            name: 'Config',
            component: () => import('@/views/VMSConfig.vue'),
            meta: {
              backButton: true,
              title: 'router_elements.config',
            },
          },
          {
            path: 'service-accounts',
            name: 'Service Accounts',
            component: () => import('@/views/ServiceAccounts.vue'),
            meta: {
              title: 'router_elements.service_accounts',
              backButton: true,
            },
          },
          {
            path: 'dashboards',
            children: [
              {
                path: 'barn-management',
                name: 'Barn Management Dashboard',
                component: () => import('@/views/dashboards/BarnManagementDashboard.vue'),
                meta: {
                  title: 'router_elements.barn_management',
                  backButton: false,
                  temporaryNavigationDrawer: true,
                },
              },
              {
                path: 'region-overview',
                name: 'Region Overview',
                component: () => import('@/views/dashboards/RegionOverview.vue'),
                meta: {
                  title: 'router_elements.region_overview',
                  backButton: false,
                  temporaryNavigationDrawer: true,
                },
              },
              {
                path: 'measurements',
                name: 'Measurements Dashboard',
                component: () => import('@/views/dashboards/MeasurementsDashboard.vue'),
                meta: {
                  title: 'router_elements.measurement_dashboard',
                  backButton: false,
                  temporaryNavigationDrawer: false,
                },
              },
              {
                path: 'dead-animals',
                name: 'Dead Animals Dashboard',
                component: () => import('@/views/dashboards/DeadAnimalStatisticsDashboard.vue'),
                meta: {
                  title: 'router_elements.dead_animals',
                  backButton: false,
                },
              },
              {
                path: 'quick-artifacts',
                name: 'Quick Artifact Dashboard',
                component: () => import('@/views/dashboards/QuickArtifactDashboard.vue'),
                meta: {
                  title: 'router_elements.quick_artifacts',
                  backButton: false,
                },
              },
            ],
          },
          {
            path: 'cameras',
            name: 'CameraList',
            component: () => import('@/views/CameraList.vue'),
            meta: {
              title: 'router_elements.cameras',
              disableDefaultAppBar: true,
            },
          },
          {
            path: 'camera/:cameraId',
            name: 'CameraView',
            component: () => import('@/views/CameraView.vue'),
            meta: {
              title: 'router_elements.camera_view',
              backButton: true,
            },
            props: true,
          },
          {
            path: 'networkDiscovery',
            name: 'Network Discovery',
            component: () => import('@/views/NetworkDiscovery.vue'),
            meta: {
              title: 'router_elements.network_discovery',
              backButton: true,
            },
          },
          {
            path: 'live/:cameraId',
            name: 'Live Stream',
            props: true,
            component: () => import('@/views/CameraLiveStream.vue'),
            meta: {
              title: 'router_elements.live_stream',
              backButton: true,
            },
          },
          {
            path: 'cameraCanvases',
            name: 'canvasList',
            component: () => import('@/views/CanvasList.vue'),
            meta: {
              title: 'router_elements.canvas_list',
              backButton: true,
            },
          },
          {
            path: 'canvas/:cameraCanvasId?',
            name: 'CameraCanvas',
            props: true,
            component: () => import('@/views/CameraCanvas.vue'),
            meta: {
              title: 'router_elements.canvas',
              backButton: true,
              temporaryNavigationDrawer: true,
            },
          },
          {
            path: 'interventions',
            name: 'BarnInterventions',
            component: () => import('@/views/BarnInterventions.vue'),
            meta: {
              title: 'router_elements.interventions',
              backButton: true,
            },
          },
          {
            path: 'documentation',
            name: 'Barn Documentation',
            component: () => import('@/views/BarnDocumentationList.vue'),
            meta: {
              title: 'router_elements.documentation',
            },
          },
          {
            path: 'frameSeek/:prePickedCamera?',
            name: 'Frame Seek',
            props: true,
            component: () => import('@/views/FrameSeek.vue'),
            meta: {
              title: 'router_elements.frame_seek',
            },
          },
          {
            path: 'measurements/:cameraId',
            name: 'Camera Measurements',
            props: true,
            component: () => import('@/views/CameraMeasurements.vue'),
            meta: {
              title: 'router_elements.camera_measurements',
              backButton: true,
            },
          },
          {
            path: 'my-incidents',
            name: 'My Incidents',
            component: () => import('@/views/MyIncidents.vue'),
            meta: {
              title: 'router_elements.my_incidents',
              backButton: true,
            },
          },
          {
            path: 'incidents',
            name: 'Incidents',
            component: () => import('@/views/IncidentList.vue'),
            meta: {
              title: 'router_elements.incidents',
            },
          },
          {
            path: 'recordings',
            name: 'Recordings',
            component: () => import('@/views/RecordingList.vue'),
            meta: {
              title: 'router_elements.recordings',
              backButton: true,
            },
          },
          {
            path: 'stream/:selectorMode/:selectorId/:timestamp?',
            name: 'RecordingStream',
            props: true,
            component: () => import('@/views/RecordingStream.vue'),
            meta: {
              title: 'router_elements.recording_stream',
              backButton: true,
            },
          },
          {
            path: 'region-drawing/:cameraId',
            name: 'Region Drawing',
            props: true,
            component: () => import('@/views/RegionDrawing.vue'),
            meta: {
              temporaryNavigationDrawer: true,
              title: 'router_elements.region_drawing',
              backButton: true,
            },
          },
          {
            path: 'region-editor/',
            name: 'Region Editor',
            component: () => import('@/views/RegionManagement.vue'),
            meta: {
              title: 'router_elements.region_editor',
              backButton: true,
            },
          },
          {
            path: 'artifacts',
            name: 'Artifacts',
            component: () => import('@/views/ArtifactList.vue'),
            meta: {
              title: 'router_elements.artifacts',
            },
          },
          {
            path: 'artifact/:artifactId',
            name: 'Artifact',
            props: true,
            component: () => import('@/views/ArtifactView.vue'),
            meta: {
              title: 'router_elements.artifact',
              backButton: true,
            },
          },
          {
            path: 'incident/:incidentId',
            name: 'IncidentView',
            props: true,
            component: () => import('@/views/IncidentView.vue'),
            meta: {
              title: 'router_elements.incident',
              backButton: true,
            },
          },
          {
            path: 'recording-export',
            name: 'Recording Export',
            component: () => import('@/views/RecordingExport.vue'),
            meta: {
              title: 'router_elements.recording_export',
            },
          },
          {
            path: 'camera-overview',
            name: 'Camera Overview',
            component: () => import('@/views/CameraOverview.vue'),
            meta: {
              title: 'router_elements.camera_overview',
            },
          },
          {
            path: 'eulas/:accountId?',
            name: 'Eulas',
            props: true,
            component: () => import('@/views/EulaList.vue'),
            meta: {
              title: 'router_elements.eulas',
              backButton: true,
            },
          },
          {
            path: 'licenses',
            name: 'Licenses',
            component: () => import('@/views/OpenSourceLicenses.vue'),
            meta: {
              title: 'router_elements.licenses',
              backButton: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/',
  },
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  useLogsStore().addLogEntry({ message: `Navigating to ${to.name}, ${to.fullPath}`, tag: 'ROUTER', level: 'INFO' })
  BackendAPI.triggerRouteChange(from.path, to.path)
  next()
})

export default router
