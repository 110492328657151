/**
 * plugins/vuetify.js
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import * as components from 'vuetify/components'

// Composables
import { createVuetify } from 'vuetify'
import { VCard } from 'vuetify/components';
// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  aliases: {
    VLayoutCard: VCard,
  },
  defaults: {
    VTextField: {
      rounded: true,
    },
    VAlert: {
      style: 'border-radius: 32px',
    },
    VSwitch: {
      falseIcon:'mdi-close',
      trueIcon:'mdi-check',
      color: 'primary',
    },
    VSelect: {
      rounded: true,
    },
    VAutocomplete: {
      rounded: true,
    },
    VSheet: {
      elevation: 2,
      variant: 'elevated',
      style: 'border-radius: 32px',
    },
    VLayoutCard: {
      elevation: 2,
      variant: 'elevated',
      style: 'border-radius: 32px;',
      class: 'pa-2 ma-2',
    },
    VBtn: {
      class: 'rounded-pill',
    },
  },
  display: {
    mobileBreakpoint: 800,
  },
  components: {
    ...components,
    //...labsComponents,
  },
  theme: {
    themes: {
      light: {
        'colors': {
          'gradient01': '#c0c0c0',
          'gradient02': '#dcdcdc',
          'gradient03': '#ffffff',
          'background': '#C2CADD',
          'surface': '#FFFFFF',
          'primary': '#254C63',
          'secondary': '#9BCCE8',
          'error': '#F44336',
          'onSurface':'#000000',
          'info': '#54B0E4',
          'success': '#7EB75C',
          'warning': '#E7AB00',
        },
      },
      dark: {
        'colors': {
          'gradient01': '#3a3939',
          'gradient02': '#353535',
          'gradient03': '#919191',
          'background': '#121212',
          'surface': '#212121',
          'primary': '#9BCCE8',
          'secondary': '#03DAC5',
          'error': '#F44336',
          'onSurface':'#FFFFFF',
          'info': '#54B0E4',
          'success': '#7EB75C',
          'warning': '#E7AB00',
        },
      },
    },
  },
})
